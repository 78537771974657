<template>
    <div class="page-table mainDiv haberTanim mb-30" id="affix-container">
        <div class="animated fadeInUp card-base baslikBg">
            {{$t("src.views.apps.popup.guncelle.title")}}
        </div>

        <el-row :gutter="10" v-loading="loading || detailLoading"
            :element-loading-text='loading ? $t("src.views.apps.popup.guncelle.updateLoading") : $t("src.views.apps.popup.guncelle.detailLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-form status-icon :model="popupForm" :rules="rulesPopup" ref="popupForm" class="demo-ruleForm">
                <el-col :lg="18" :md="18" :sm="24" :xs="24">
                    <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft">
                        <div>
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.popup.tanimlama.baslik")' prop="baslik">:
                                        <el-input size="small" v-model="popupForm.baslik" :placeholder='$t("src.views.apps.popup.tanimlama.baslikPlace")'></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.popup.tanimlama.link")' prop="link">:
                                        <el-select v-model="popupForm.link" :placeholder="'Link Seçiniz'" size="mini" style="width: 100%">
                                            <div v-loading="seoLinkLoading"
                                            :element-loading-text='$t("src.views.apps.popup.tanimlama.linkListLoading")'
                                            element-loading-spinner="el-icon-loading"
                                            element-loading-background="rgba(255, 255, 255, 1)">
                                                <el-option
                                                v-for="(item, index) in seoLinkList"
                                                :key="index"
                                                :label="item.link"
                                                :value="item.link">
                                                </el-option>
                                            </div>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.popup.tanimlama.tarih")' prop="tarih">:
                                        <el-date-picker
                                            size="small"
                                            style="width: 100%"
                                            v-model="popupForm.tarih"
                                            type="datetimerange"
                                            :start-placeholder='$t("src.views.apps.popup.guncelle.startDate")'
                                            :end-placeholder='"End Date"'
                                            format="yyyy-MM-dd HH:mm:ss"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            :default-time="['08:00:00', '17:00:00']">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.popup.tanimlama.icerik")' prop="icerik">:
                                        <ckeditor :editor="editor" :config="editorConfig" v-model="popupForm.icerik"></ckeditor>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                    <el-collapse style="margin-top: 10px" v-model="activeCollapse">
                        <el-row>
                            <el-col :span="24">
                                <draggable data-source="juju" class="list-group" group="a">
                                    <el-collapse-item class="card-shadow--medium animated fadeInRight" title="Resim Ayarları" name="4">
                                        <el-row>
                                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                                <el-form-item :label='$t("src.views.apps.sayfa.tanimlama.resim") + " (800x600)"' :prop="popupForm.resim.length == 0 ? 'resim' : ''">:
                                                    <el-upload
                                                        id="popguncelle-resim"
                                                        class="resimler"
                                                        action=""
                                                        list-type="picture-card"
                                                        :file-list="imageListMain"
                                                        :auto-upload="false"
                                                        accept=".jpg,.jpeg,.png"
                                                        :on-change="imageUpload"
                                                        :on-remove="imageRemove">
                                                        <i slot="default" class="el-icon-plus"></i>
                                                    </el-upload>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-collapse-item>
                                </draggable>
                            </el-col>
                        </el-row>
                    </el-collapse>
                </el-col>


                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                    <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
                        <el-collapse-item class="card-shadow--medium" name="1" :title='$t("src.views.apps.genel.durum")'>
                            <el-row>
                                <el-button
                                    style="margin-bottom: 10px"
                                    size="mini" v-on:click="popupGuncelle('popupForm')"
                                    class="onayBtn"
                                    type="primary">
                                    {{$t('src.views.apps.genel.guncelle')}}
                                </el-button>
                            </el-row>
                        </el-collapse-item>
                        <draggable class="list-group" group="a">
                            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.popup.tanimlama.gorunum")' name="2">
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item prop="gorunum">
                                            <el-switch
                                                v-model="popupForm.gorunum"
                                                active-value="1"
                                                inactive-value="0"
                                                active-text="Tüm Sayfalar"
                                                inactive-text="Ana Sayfa">
                                            </el-switch>
                                        </el-form-item>
                                        <div style="font-style: italic">
                                            {{$t("src.views.apps.popup.tanimlama.gorunumAciklama")}}
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>
                            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.popup.tanimlama.otomatik")' name="3">
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item prop="otomatik">
                                            <el-switch
                                                v-model="popupForm.otomatik"
                                                active-value="1"
                                                inactive-value="0"
                                                active-text="Otomatik"
                                                inactive-text="Manuel">
                                            </el-switch>
                                        </el-form-item>
                                        <div style="font-style: italic">
                                            {{$t("src.views.apps.popup.tanimlama.otomatikAciklama")}}
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>
                        </draggable>
                    </el-collapse>
                </el-col>
            </el-form>
        </el-row>
    </div>
</template>


<script>
    import popupService from '../../../WSProvider/PopService'
    import seoService from "../../../WSProvider/SeoService"
    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'

    import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
    import draggable from 'vuedraggable'
    import Resim from '@/components/Resim.vue'

    import JQuery from 'jquery';
    let $ = JQuery;

    export default {
        name: "PopDuzenle",
        components: {
            Resim,
            draggable,
        },
        mounted() {
            this.updatePopData = this.$store.getters.getPopData
            this.getPopDetay();
            this.getSeoLink();
        },
        computed:{
            popStoreData(){
                return this.$store.getters.getPopData;
            }
        },
        watch: {
            popStoreData(val){
                this.updatePopData = this.$store.getters.getPopData
                this.getPopDetay();
            }
        },
        data() {
            return {
                editor: ClassicEditor,
                editorConfig: {
                    language: 'tr'
                },
                seoLinkList: [],
                detailLoading: false,
                loading: false,
                seoLinkLoading: false,
                imageListMain: [],
                updatePopData: {},
                activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                popupForm: {
                    popId: "",
                    baslik: '',
                    icerik: '',
                    resim: [],
                    imageBase: '',
                    link: '',
                    tarih: [],
                    durum: '1',
                    gorunum: '0',
                    otomatik: '1',
                },
                rulesPopup: {
                    baslik: [{
                        required: true,
                        message: "Lütfen Başlık Giriniz",
                        trigger: 'blur'
                    }],
                    tarih: [{
                        required: true,
                        message: "Lütfen Tarih Seçiniz",
                        trigger: 'blur'
                    }],
                    resim: [{
                        required: true,
                        message: "Lütfen Resim Seçiniz",
                        trigger: ['blur', 'trigger']
                    }],
                },
            }
        },

        methods: {
            getSeoLink() {
                try{
                    this.seoLinkLoading = true;
                    seoService.seoList(1).then(response => {
                        if (response.status === 200) {
                            localStorage.setItem("userDataDemirag", response.token)
                            this.seoLinkList = response.data.data;
                        }
                        this.seoLinkLoading = false;
                    }).catch(error => {
                        if(err.responseJSON){
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else{
                                notification.Status(250, this);
                            }
                        }
                        this.seoLinkLoading = false;
                    })
                }catch(e){
                    this.seoLinkLoading = false;
                }
            },

            getPopDetay() {
                try{
                    this.popupForm.resim = [];
                    this.imageListMain = [];

                    this.detailLoading = true;
                    popupService.popDetay(this.updatePopData.popID).then(response => {
                        if(response.status == 200){
                            if (response.data.resim) {
                                if(response.data.resim !== ''){
                                    this.imageListMain.push({
                                        name: response.data.resim,
                                        url: popupService.imagePath + response.data.resim
                                    });
                                }
                                this.popupForm.resim.push(response.data.resim)
                                $('#popguncelle-resim .el-upload--picture-card').hide();
                            }
                
                            this.popupForm.popId = response.data.popID;
                            this.popupForm.baslik = response.data.baslik;
                            this.popupForm.icerik = response.data.icerik ? response.data.icerik : ''
                            this.popupForm.gorunum = response.data.gorunum;
                            this.popupForm.otomatik = response.data.otomatik;
                            this.popupForm.durum = response.data.durum;
                            this.popupForm.link = response.data.link;

                            this.popupForm.tarih.push(response.data.baslamaTarihi);
                            this.popupForm.tarih.push(response.data.bitisTarihi);
                            this.detailLoading = false;
                        }
                        this.popupForm = functions.removeSlashesAndDecodeStrings(this.popupForm)
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.detailLoading = false;
                    })
                }catch(e){
                    this.detailLoading = false;
                }
            },

            imageUpload(file) {
                this.imageListMain = []
                this.popupForm.resim = []
                if ((file.raw["type"] === 'image/jpg' || file.raw["type"] === 'image/jpeg' || file.raw["type"] === 'image/png') && this.imageListMain.length < 2) {
                    var item = {
                        name: file.name,
                        url: file.url,
                        id: file.raw.lastModified,
                        size: file.raw.size
                    };
                    this.imageListMain.push(item)

                    file.raw["tmp_name"] = file.url;
                    
                    this.popupForm.resim.push(file.raw)
                     $('#popguncelle-resim  .el-upload--picture-card').hide();
                }
            },

            imageRemove(file) {
                $('#popguncelle-resim .el-upload--picture-card').show();
                this.imageListMain.splice(this.imageListMain.indexOf(file), 1);
                this.popupForm.resim = [];
            },

            //popID, link, baslik, icerik, resim, otomatik, baslamaTarihi, bitisTarihi, gorunum, durum
            popupGuncelle(formName) {
                if(this.validateForm(formName)){
                    this.openConfirmDialog("Pop-up güncellemek istediğinize emin misiniz?").then(() => {
                        try{
                            this.loading = true;
                            popupService.popGuncelle(this.popupForm.durum, this.popupForm.popId, this.popupForm.link, this.popupForm.baslik, this.popupForm.icerik, this.popupForm.resim[0] ? this.popupForm.resim[0] : '', this.popupForm.otomatik, this.popupForm.tarih[0], this.popupForm.tarih[1], this.popupForm.gorunum, ).then((response) => {
                                if (response.status == 200) {
                                    localStorage.setItem("userDataDemirag", response.token)
                                    this.resetForm(formName)
                                    EventBus.$emit("popList", true)
                                    this.sayfaKapat('popupguncelle')
                                }
                                this.loading = false;
                                notification.Status("success", this, response.msg)
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    if(error.status == 401){
                                        notification.Status(150, this);
                                    }
                                    else notification.Status("errorMsg", this, error.errMsg)
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.loading = false;
                            })
                        }catch(e){
                            this.loading = false;
                        }
                    })
                }
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.sayfaKapat('popupduzenle')
            },
        }
    }
</script>

<style scoped>
    .el-tag + .el-tag {
        margin-left: 10px;
    }

</style>